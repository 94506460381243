import type { PageContext } from "vike/types";
import { redirect } from "vike/abort";

export default (pageContext: PageContext) => {
  // Parse the URL
  const urlParts = pageContext.urlPathname.slice(1).split("/");

  // Only URLs that start with `/bibliothek`
  if (urlParts[0] !== "bibliothek") {
    return false;
  }

  // Never allow the url to be only "bibliothek" without a tab selected
  if (!["inhalte", "chronik", "gesetze"].includes(urlParts[1])) {
    throw redirect("/bibliothek/gesetze");
  }

  // no "/channel" without channel route params
  if (urlParts.at(2) === "channel" && urlParts.length === 3) {
    throw redirect("/bibliothek/inhalte");
  }

  return {
    routeParams: {
      view: urlParts.at(2) === "channel" ? "channel" : urlParts.at(1),
      channelId: urlParts.at(2) === "channel" ? urlParts.at(3) : "",
    },
  };
};
